// Typography
//
// Font, line-height, and color for body text, headings, and more.
@import url("https://fonts.googleapis.com/css?family=Poppins:300,700");

$font-family-sans-serif: "Poppins", sans-serif;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;

$navbar-default-link-color: black;
$navbar-default-link-hover-color: lightgray;
$navbar-default-link-hover-bg: transparent;
$navbar-default-link-active-color: #555;
$navbar-default-link-disabled-color: #ccc;
$navbar-default-link-disabled-bg: transparent;
