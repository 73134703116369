// Extra small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .header-logo {
    width: 250px;
  }

  .sample-box-wrapper {
    margin-top: 0;
  }

  .sample-box-mt {
    margin-top: 10%;
  }

  .btn-mobile--centered {
    text-align: right;
  }

  .carousel-navigation {
    text-align: right;
  }

  .carousel-navigation > a {
    margin-top: 0;
  }

  .background-asset--polygon {
    display: block;
    right: 0;
  }

  // Blog
  .blog-hero-image {
    max-height: 400px;
  }

  .blog-banner-text {
    font-size: 28px;
    text-align: center;
    line-height: 40px;
    max-width: 750px;
    margin: 0 auto;
    padding-bottom: 20px;
  }

  .sub-header--medium {
    font-size: 60px;
    line-height: 72px;
    padding-bottom: 25px;
  }

  .hexxx {
    @include hexagon(200px, white, 5px solid white, 90deg);
  }

  .p--2-columns {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 20px;
    -moz-column-gap: 20px;
    column-gap: 50px;
    column-rule-width: 1px;
  }

  .milestone-icon--mobile:not(:first-child) {
    margin-top: 0;
  }

  .footer-link-table {
    margin-top: 0px;
  }

  footer {
    padding-top: 90px;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .sample-box {
    width: 155px;
    height: 155px;
    padding-top: 30px;
    margin-bottom: 28px;
    img {
      height: 40px;
    }
    p {
      margin-top: 15px;
      font-size: 13px;
    }
  }

  .blog-banner-text {
    font-size: 60px;
    text-align: center;
    line-height: 72px;
    max-width: 750px;
    margin: 0 auto;
    padding-bottom: 20px;
  }

  .blog-title {
    font-size: 60px;
    line-height: 72px;
    padding-bottom: 30px;
  }

  .hexxx {
    @include hexagon(220px, white, 5px solid white, 90deg);
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  h1,
  h2 {
    font-size: 80px;
    line-height: 96px;
  }

  .h1-intro {
    font-size: 50px;
    line-height: 65px;
  }

  .navbar-light {
    background: transparent;
  }

  .navbar {
    padding-top: 30px;
  }

  .card-btn {
    bottom: -30px;
  }

  .sample-box {
    width: 210px;
    height: 210px;
    padding-top: 60px;
    margin-bottom: 28px;
    img {
      height: 60px;
    }
    p {
      margin-top: 15px;
      font-size: 16px;
    }
  }

  .bg--hexagons-blurred {
    background-image: url("../img/background.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .btn--sustain-static {
    margin-top: -100px;
  }

  .card {
    max-width: 295px;
  }

  .hexxx {
    @include hexagon(250px, white, 5px solid white, 90deg);
  }

  .nav-item:after,
  .blog-category-link:after {
    margin: 0 auto;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .h1-intro {
    font-size: 80px;
    line-height: 95px;
  }

  .sample-box {
    width: 262px;
    height: 262px;
    padding-top: 70px;
    margin-bottom: 28px;
    img {
      height: 60px;
    }
    p {
      margin-top: 30px;
      font-size: 20px;
    }
  }

  .card {
    max-width: 357px;
  }
}
