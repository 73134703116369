// Layout
html {
  height: 100vh;
}

body {
  position: relative;
  min-height: 100vh;
  background-image: url("../img/background-green-single.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

main {
  padding-bottom: 400px;
}

.masthead {
  height: 100vh;
  min-height: 710px;
  background: none;
}

.cover-container {
  max-width: 42em;
}

footer {
  padding-top: 55px;
  background: black;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 400px;
  text-align: center;
}

.footer-link {
  color: white;
  &:hover {
    color: lightgray;
    text-decoration: none;
  }
}

.footer-sub {
  background: white;
  width: 100%;
}

.footer-copyright {
  color: white;
  font-size: 14px;
  padding-bottom: 10px;
  padding-top: 10px;
  border-top: 1px solid rgb(53, 53, 53);
}

.footer-link-table {
  color: white;
  margin-top: 50px;
}

.split-column > img {
  max-height: 800px;
}

// Typography
h1,
h2 {
  font-size: 38px;
  color: black;
  font-weight: bolder;
  padding-bottom: 30px;
}

h3 {
  font-size: 22px;
  line-height: 24px;
  font-weight: bold;
}

.intro-text {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  color: black;
  font-size: 18px;
  line-height: 32px;
}

.blog-category-link {
  color: black;
  font-size: 24px;
  border: none;
  background: none;
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}

.sub-header--small {
  font-size: 40px;
  line-height: 48px;
  padding-bottom: 25px;
}

.sub-header--medium {
  font-size: 40px;
  line-height: 48px;
  padding-bottom: 25px;
}

.p--2-columns {
  -webkit-column-count: 1;
  -moz-column-count: 1;
  column-count: 1;
  -webkit-column-gap: 20px;
  -moz-column-gap: 20px;
  column-gap: 50px;
  column-rule-width: 1px;
}

.dropdown-box {
  margin-left: -7.25px !important;
}

.milestone-icon--mobile:not(:first-child) {
  margin-top: 35px;
}

// Navbar

.header-logo {
  width: 150px;
}

.navbar {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.navbar-toggler {
  color: #222d34;
  border: none;
}

.navbar-transparent {
  background: transparent !important;
}

.navbar-light {
  background: white;
}

.nav-link {
  color: black !important;
  font-size: 14px;
  font-weight: bold;
  &:hover {
    color: black;
  }
}

.nav-item {
  padding-right: 20px;
  &:after {
    opacity: 0;
  }
  &:hover {
    &:after {
      opacity: 1;
    }
  }
}

.active {
  .nav-item {
    &:after {
      opacity: 1;
    }
  }
}

.nav-item:after,
.blog-category-link:after {
  content: ""; /* This is necessary for the pseudo element to work. */
  display: block; /* This will put the pseudo element on its own line. */
  width: 30%; /* Change this to whatever width you want. */
  border-bottom: 5px solid black; /* This creates the border. Replace black with whatever color you want. */
}

.blog-category-link {
  font-size: 24px;
  font-weight: bold;
  &:after {
    opacity: 0;
  }
  &:hover {
    color: black;
    &:after {
      opacity: 1;
    }
  }
}

.list-inline-item:not(:last-child) {
  margin-right: 3rem;
}

.navbar-toggler > .close {
  display: inline;
}
.navbar-toggler.collapsed > .close,
.navbar-toggler:not(.collapsed) > .navbar-toggler-icon {
  display: none;
}

// Sub nav

.sub-nav {
  height: 60px;
  width: 100%;
  margin-bottom: 20px;
}

.sub-nav-btn {
  font-size: 20px;
  line-height: 24px;
  font-weight: normal;
  height: 60px;
}

.sub-nav-btn-text {
  display: inline-block;
  padding-top: 15px;
  padding-left: 15px;
}

.sub-nav-btn--ion-icon {
  position: relative;
  font-size: 60px;
}

// Intro

.intro-block {
  margin-top: 0;
}

.sub-content-box {
  margin-bottom: 60px;
}

// Cards

.card {
  margin: 7.5px;
  border-radius: 0;
  border: none;
  height: 529px !important;
  text-overflow: ellipsis;
  overflow: hidden;
  &:hover {
    a,
    p,
    h1,
    h2 {
      text-decoration: none;
    }
  }
}

.card-img-top {
  border-radius: 0;
  border: none;
}

.card-img-top {
  border-radius: 0;
  height: 235px;
}

.card-subtitle {
  font-size: 16px;
  line-height: 24px;
  color: #21708d;
}

.card-title {
  font-size: 22px;
  font-weight: 600;
  height: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: black;
}

.card-text {
  font-size: 14px;
  max-height: 72px;
  line-height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.card-btn {
  border-radius: 0;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  border: none;
  background: #21708d;
  position: absolute;
  bottom: 0;
  transition: all 0.5s ease 0s;
  &:hover {
    bottom: 0;
    background: #21708d;
  }
}

.card--jobs {
  height: auto !important;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06)!important;
  .card-title {
    margin-top: 10px;
  }
  .card-text {
    max-height: 190px !important;
    margin-bottom: 30px;
  }
}

// Carousel

.carousel-navigation > button {
  min-width: 50px;
  margin-right: -5px;
  border: 1px solid black;
}

.carousel-navigation {
  text-align: center;
}

.blog-carousel--hidden {
  display: none !important;
}

.slick-arrow {
  border: none;
  font-size: 30px !important;
  min-width: 60px !important;
  height: 60px;
}

// Buttons

.btn-dark {
  background: black;
  color: white;
  border-radius: 0;
  font-size: 20px;
  min-width: 250px;
}

.btn-dark--inverse {
  background: white;
  color: black;
  border: 1px solid black;
  &:hover {
    background: black;
  }
}

.carousel-navigation > a {
  min-width: 240px;
}

.btn-mobile--centered {
  text-align: center;
}

// Blog

.blog-hero-image {
  max-height: 300px;
}

.blog-title {
  font-size: 34px;
  line-height: 44px;
  padding-bottom: 30px;
}

.blog-subtitle {
  font-size: 18px;
  font-style: italic;
  line-height: 32px;
}

.blog-textblock-title {
  font-size: 28px;
  line-height: 32px;
  padding-bottom: 10px;
}

// background assets

.background-asset {
  position: absolute;
  z-index: -999999;
}

.background-asset--polygon {
  display: none;
  right: 0;
}

.background-asset--green {
  width: 1400px;
  height: 1406px;
  background-image: linear-gradient(152deg, var(--blue-green), var(--light-olive-green));
}

.blog-banner-text {
  font-size: 18px;
  text-align: center;
  line-height: 36px;
  max-width: 750px;
  margin: 0 auto;
  padding-bottom: 20px;
}

.logo--centered {
  position: relative;
  right: 60px;
  height: 400px;
}

// Utils

.bg--white {
  background: white !important;
  border-bottom: 1px solid black;
}

.bg--light-gray {
  background: #f8f8f8;
}

.pointer {
  cursor: pointer;
}

.btn-link {
  color: black !important;
  font-size: 18px !important;
}

.half {
  width: 50%;
}

.mt-10-perc {
  margin-top: 10%;
}

.mt-20-perc {
  margin-top: 20%;
}

.mb-10-perc {
  margin-bottom: 10%;
}

.mb-20-perc {
  margin-bottom: 20%;
}

.pb-10-perc {
  padding-bottom: 10%;
}

.pb-20-perc {
  padding-bottom: 20%;
}

.pl-120 {
  padding-left: 120px;
}

.pr-120 {
  padding-right: 120px;
}

.pb-100 {
  padding-bottom: 100px;
}

.resize-fit-center {
  max-width: 50%;
  max-height: 50%;
  vertical-align: middle;
}

.helper--spacer {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

@mixin hexagon($size, $color, $border: 0, $rotation: 0) {
  position: relative;
  width: $size;
  height: ($size * 0.577);
  background-color: $color;
  margin: ($size * 0.288) 0;
  border-left: $border;
  border-right: $border;
  transform: rotate($rotation);

  &:before,
  &:after {
    content: "";
    position: absolute;
    @if $border == 0 {
      width: 0;
      left: 0;
      border-left: ($size/2) solid transparent;
      border-right: ($size/2) solid transparent;
    } @else {
      z-index: 1;
      width: ($size * 0.707);
      height: ($size * 0.707);
      -webkit-transform: scaleY(0.5774) rotate(-45deg);
      -ms-transform: scaleY(0.5774) rotate(-45deg);
      transform: scaleY(0.5774) rotate(-45deg);
      background-color: inherit;
      left: ($size * 0.129);
    }
  }

  &:before {
    z-index: -1;
    @if $border == 0 {
      bottom: 100%;
      border-bottom: ($size * 0.288) solid $color;
    } @else {
      top: -($size * 0.353);
      border-top: $border;
      border-right: $border;
    }
  }

  &:after {
    z-index: -1;
    @if $border == 0 {
      top: 100%;
      width: 0;
      border-top: ($size * 0.288) solid $color;
    } @else {
      bottom: -($size * 0.353);
      border-bottom: $border;
      border-left: $border;
    }
  }
}

.hexxx {
  @include hexagon(250px, white, 5px solid white, 90deg);
}

.hexxx > img {
  max-width: 150px;
  transform: rotate(-90deg);
  z-index: 9999;
}

body > main > div.container > div.row.mx-auto.text-center.sal-animate > div:nth-child(3) > div > img{
  max-width: 200px;
  margin-top: 40px;
}

// Sample Box
.sample-box {
  box-shadow: 0 22px 54px 0 rgba(58, 60, 58, 0.11);
  background-color: #ffffff;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 5px;
  margin-bottom: 28px;
  height: 280px;
  width: 280px;
  img {
    height: 40px;
  }
  p {
    margin-top: 15px;
    font-size: 13px;
    font-weight: bold;
  }
}

.sample-box-wrapper {
  margin-top: 10%;
}

.scroll-up-arrow {
  position: absolute;
  bottom: 385px;
  right: 20px;
  transform: rotate(90deg);
  &:hover {
    cursor: pointer;
  }
}

.sample-box-header--green {
  font-size: 60px;
  color: #1fa966;
  line-height: 72px;
  font-weight: bold;
}

// Achievement box

.achievement-box {
  box-shadow: 0 22px 54px 0 rgba(58, 60, 58, 0.11);
  background-color: #ffffff;
  text-align: center;
  height: 240px;
  width: 240px;
  margin-bottom: 20px;
}

.achievement-box-inner {
  padding-top: 75px;
}

// Forms

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  background-clip: padding-box;
  border-bottom: 2px solid #000000;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 0;
}

.form-group {
  margin-bottom: 3rem;
}

.form-group > label {
  color: black;
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
}

// Radios

.radio {
  position: relative;
  cursor: pointer;
  line-height: 20px;
  color: black;
  font-size: 20px;
  line-height: 24px;
  margin: 15px 15px 15px 0;

  &:hover {
    cursor: pointer;
  }
}

.radio .label {
  position: relative;
  display: block;
  float: left;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  border: 2px solid gray;
  border-radius: 100%;
  -webkit-tap-highlight-color: transparent;
}

.radio .label:after {
  content: "";
  position: absolute;
  top: 3px;
  left: 3px;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: black;
  transform: scale(0);
  transition: all 0.2s ease;
  opacity: 0.08;
  pointer-events: none;
}

.radio:hover .label:after {
  transform: scale(3.6);
}

input[type="radio"]:checked + .label {
  border-color: black;
}

input[type="radio"]:checked + .label:after {
  transform: scale(1);
  transition: all 0.2s cubic-bezier(0.35, 0.9, 0.4, 0.9);
  opacity: 1;
}

// Alerts

.alert {
  border: none;
  border-radius: 0;
}

.alert-success {
  background: #1fa966;
  color: white;
  margin-bottom: 30px;
}

.alert-danger {
  background: #f4645f;
  color: white;
  margin-bottom: 30px;
}

// Hexagon

.hexagon--large {
  height: 290px;
  width: 290px;
  background-image: url("../img/icons/polygon-copy-9.svg");
  background-size: 345px 345px;
  background-repeat: no-repeat;
  background-position-x: -40px;
  white-space: nowrap;
  text-align: center;
}

.hexagon {
  display: inline-block;
  position: relative;
  width: 70px;
  height: 40.41px;
  background-color: transparent;
  margin: 20.21px 0;
  border-left: solid 2px #333333;
  border-right: solid 2px #333333;
}

.hexagon--white {
  background: white;
}

.hexagon:before,
.hexagon:after {
  content: "";
  position: absolute;
  z-index: 1;
  width: 49.5px;
  height: 49.5px;
  -webkit-transform: scaleY(0.5774) rotate(-45deg);
  -ms-transform: scaleY(0.5774) rotate(-45deg);
  transform: scaleY(0.5774) rotate(-45deg);
  background-color: inherit;
  left: 8.2513px;
}

.hexagon:before {
  top: -24.7487px;
  border-top: solid 2.8284px #333333;
  border-right: solid 2.8284px #333333;
}

.hexagon:after {
  bottom: -24.7487px;
  border-bottom: solid 2.8284px #333333;
  border-left: solid 2.8284px #333333;
}

.hexagon > ion-icon {
  position: absolute;
  z-index: 2;
  font-size: 40px;
  left: 13px;
  top: 2px;
}

.hexagon-link {
  margin-bottom: 70px;
  margin-left: 1em;
}

.hexagon-banner {
  margin-top: 5em;
}
